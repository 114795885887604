import React from "react";
import logo from "../Component/img/logo.png";  
import Offcanvas from "../Component/Offcanvas";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faUser, faMoneyBillAlt } from '@fortawesome/free-solid-svg-icons'; 


function ReferHistory() {
  return (
    <div className="vh-100">
      <div className="bgred ">
        <div className="d-flex align-items-center px-2 pt-2">
          <div className="me-2">
            <FontAwesomeIcon icon={faArrowLeft} className="baricn mt-2" />
          </div>
          <div className="bbhg me-2">Refer History</div>
        </div>
      </div>

     

      <div className=" m-2 ">
        <div className="row">
          <div className="col py-3">
            
            <table className="table table-bordered mb-1 mt-1">
                <tr className="">
                    <th className="py-3 backrg1 lok111">Date</th>
                    <th className="py-3 backrg1">Amount</th>
                    <th className="py-3 backrg1 lok112">User</th> 
                </tr>
                <tr className=" ">
                    <td className="text-center py-2 pop3">2024-07-23 <br/>11:42 AM </td>
                    <td className="text-center py-2 pop3">10</td>
                    <td className="text-center py-2 pop3">jjj</td> 
                </tr>

                <tr className=" ">
                    <td className="text-center py-2 pop3">2024-07-23 <br/>11:42 AM </td>
                    <td className="text-center py-2 pop3">10</td>
                    <td className="text-center py-2 pop3">jjj</td> 
                </tr>

                <tr className=" ">
                    <td className="text-center py-2 pop3">2024-07-23 <br/>11:42 AM </td>
                    <td className="text-center py-2 pop3">10</td>
                    <td className="text-center py-2 pop3">jjj</td> 
                </tr>
 
                
 
  
     
            </table>

             
          </div>

          
        </div>
      </div>

     
    </div>
  );
}

export default ReferHistory;
