import React from "react";
import logo from "../Component/img/logo.png";  
import Offcanvas from "../Component/Offcanvas";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faUser, faMoneyBillAlt } from '@fortawesome/free-solid-svg-icons'; 


function GameHistory() {
  return (
    <div className="vh-100">
      <div className="bgred ">
        <div className="d-flex align-items-center px-2 pt-2">
          <div className="me-2">
            <FontAwesomeIcon icon={faArrowLeft} className="baricn mt-2" />
          </div>
          <div className="bbhg me-2">Game History</div>
        </div>
      </div>

     

      <div className=" m-2 ">
<div className="row p-0 m-0">
    <div className="col p-0 m-0"><div class="btnn5">10/09/2024</div></div>
    <div className="col p-0 m-0"><div class="btnn5">10/09/2024</div></div>
    <div className="col p-0 m-0"><button className="btnn">Submit</button></div>
</div>
 
         
      </div>

     
    </div>
  );
}

export default GameHistory;
