import React from "react";
import logo from "../Component/img/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faBars, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "../Component/Offcanvas";

function Guti() {
  // Helper function to generate rows
  const renderRows = () => {
    const rows = [];
    for (let i = 0; i <= 99; i += 2) {
      rows.push(
        <tr key={i}>
          <td><strong>{i.toString().padStart(2, '0')}</strong></td>
          <td><input type="text" className="form-control mxinp2" placeholder="Amount" /></td>
          <td><strong>{(i + 1).toString().padStart(2, '0')}</strong></td>
          <td><input type="text" className="form-control mxinp2" placeholder="Amount" /></td>
        </tr>
      );
    }
    return rows;
  };

  return (
    <div className="vh-100">
      {/* Offcanvas Component */}
      <Offcanvas />
      <div className="bgred">
        <div className="row">
          <div className="col-2">
            <FontAwesomeIcon icon={faArrowLeft} className="baricn mt-3" />
          </div>
        </div>
      </div>
<div className="vh-90">

   
      

      {/* Date Button */}
      <div className="mt-3">
        <div className="lo txt-red">GUTI</div>
        <div className="btnn4">10/09/2024 TUESDAY BET RUNNING</div>
      </div>

      {/* Table with inputs */}
      <div className="row p-0 m-0 over ">
        <table className="table text-center">
          <tbody>{renderRows()}</tbody>
        </table>
      </div>

       

      <div className="row p-0 m-0">
          <div className="col p-0 m-0"><div className="btnn4">Total Points:0</div></div>
          <div className="col p-0 m-0"><div className="btnn4">SUBMIT</div></div>
        
      </div>
      </div>
    </div>
  );
}

export default Guti;
