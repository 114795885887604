import React from "react";
import logo from "../Component/img/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "../Component/Offcanvas";
import what from "../Component/img/what.svg";
import Telegram from "../Component/img/Telegram.webp";


function HelpSupport() {
  return (
    <div className="vh-100">
      <div className="bgred ">
        <div className="d-flex align-items-center px-2 pt-2">
          <div className="me-2">
            <FontAwesomeIcon icon={faArrowLeft} className="baricn mt-2" />
          </div>
          <div className="bbhg me-2">Help & Support</div>
        </div>
      </div>

      <div className="m-2 px-2 ">
        <div className="row">
          <div className="col py-3 text-start">
            <div className="wlt3">Email :</div>
          </div>
          <div className="col py-3 text-start">
            <div className="wlt3">mlclub744@gmail.com</div>
          </div>
        </div>

        <div className="row">
          <div className="col py-3 text-start">
            <div className="wlt3">Email :</div>
          </div>
          <div className="col py-3 text-start">
            <div className="wlt3">
              Khasi Hills Archery Sports Institute KHASI Shillong pin 793001
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col py-3 text-center">
            <div className="wlt3">Monday to Saturday ( 10 AM to 7 PM )</div>
          </div>
        </div>
      </div>

      <div className="px-3">
        <div className="row bgred1 mx-1 py-2">
          <div className="col-4 py-3 brt1">
            <div className="text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="20"
                fill="currentColor"
                class="bi bi-telephone-fill"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
                />
              </svg>
              <div class="text-white huj ">Call Us</div>
            </div>
           
          </div>

          <div className="col-4 py-3 brt1">
            <div className="text-white ">
               <img src={what} className="img-fluid imgh" />
               <div class="text-white huj">Whatsapp Us</div>
            </div>
            
          </div>

          <div className="col-4 py-3">
            <div className="text-white">
               <img src={Telegram} className="img-fluid imgh" />
               <div class="text-white huj">Follow Us</div>
            </div>
            
          </div>

        </div>
      </div>
    </div>
  );
}

export default HelpSupport;
