import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Pages/Login';
import Teer from './Pages/Teer';
import Game from './Pages/Game';
import './App.css';
import './Component/common.css'; 
import Login2 from './Pages/Login2';
import HousingAndEnding from './Pages/HousingAndEnding';
import Guti from './Pages/Guti';
import SendMoneyToFriend from './Pages/SendMoneyToFriend';
import HelpSupport from './Pages/HelpSupport';
import Wallet1To2 from './Pages/Wallet1To2';
import GameRate from './Pages/GameRate';
import ChangePassword from './Pages/ChangePassword';
import HowToPlay from './Pages/HowToPlay';
import WithDrawFund from './Pages/WithdrawFund';
import AddFund from './Pages/AddFund';
import FriendTransferHistory from './Pages/FriendTransfereHistory';
import FcBidHistory from './Pages/FcBidHistory';
import BsBidHistory from './Pages/BsBidHistory';
import ReferHistory from './Pages/ReferHistory';
import PointHistory from './Pages/PointHistory';
import GameHistory from './Pages/GameHistory';
import Profile from './Pages/Profile';
import AddBankAccount from './Pages/AddBankAccount';

function App() {
  
  return (
    <Router>
      <div className="App main-body justify-content-center d-flex vh-100">
        
        <div className="main-body2">
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/Loginfirst" element={<Login2 />} />
            <Route path="/teer" element={<Teer />} />
            <Route path="/game" element={<Game />} />
            <Route path="/housing" element={<HousingAndEnding />} />
            <Route path="/guti" element={<Guti />} />
            <Route path="/sendmoney" element={<SendMoneyToFriend />} />
            <Route path="/help" element={<HelpSupport />} />
            <Route path="/wallet1to2" element={<Wallet1To2 />} />
            <Route path="/gamerate" element={<GameRate />} />
            <Route path="/changepassword" element={<ChangePassword />} />
            <Route path="/howtoplay" element={<HowToPlay />} />
            <Route path="/withdraw" element={<WithDrawFund />} />
            <Route path="/addfund" element={<AddFund />} />
            <Route path="/friendtransfer" element={<FriendTransferHistory />} />
            <Route path="/fcbidhistory" element={<FcBidHistory />} />
            <Route path="/bcbidhistory" element={<BsBidHistory />} />
            <Route path="/referhistory" element={<ReferHistory />} />
            <Route path="/pointhistory" element={<PointHistory />} />
            <Route path="/gamehistory" element={<GameHistory />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/addbank" element={<AddBankAccount />} />
            
            
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
