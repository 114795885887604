import React from "react";
import logo from "../Component/img/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "../Component/Offcanvas";

function Game() {
  return (
    <div className="vh-100">
   
      <div className="bgred ">
      <div className="d-flex align-items-center px-2 pt-2">
  <div className="me-2">
    <FontAwesomeIcon icon={faArrowLeft} className="baricn mt-2" />
  </div>
  <div className="bbhg me-2">
    KHANAPARA NIGHT TEER FR DASHBOARD
  </div>
</div>
      </div>
      <div className="d-flex justify-content-center mt-5">
  <div className="kih align-content-center">
    Guti Play
  </div>
</div>
<div className="d-flex justify-content-center mt-3">
  <div className="kih align-content-center">
    Housing <br/> & <br/> Ending
  </div>
</div>
    </div>
  );
}

export default Game;
