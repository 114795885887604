import React from "react";
import logo from "../Component/img/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faBars } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "../Component/Offcanvas";

function Teer() {
  return (
    <div className="vh-100">
      {/* Offcanvas Component */}
      <Offcanvas />

      {/* Background */}
      <div className="bgred ">
        <div className="row">
          <div className="col-2">
            <FontAwesomeIcon
              icon={faBars}
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasExample"
              aria-controls="offcanvasExample"
              className="baricn mt-2"
            />
          </div>
        </div>
      </div>

      {/* Scrolling Text */}
      <div className="scroll-text">
        <div className="scroll-text-content">
          All Services are run by Teer Online
        </div>
      </div>

      {/* Disclaimer Card */}
      <div className="card m-2 bd2">
        Our Application Licence number is '1927'. We are happy to see you
        winning with us. Disclaimer: This game may be habit-forming or
        financially risky. Play responsibly.
      </div>

      {/* Date Button */}
      <div>
        <button className="btnn2">09-09-2024</button>
      </div>

      {/* List of Cards */}
      <div className="mnj">
        {[...Array(6)].map((_, index) => (
          <div className="card crd1" key={index}>
            <div className="row">
              <div className="col-3">
                <img src={logo} className="img-fluid ft2" alt="Logo" />
              </div>
              <div className="col-6 align-content-center">
                <div className="lo">SHILLONG TEER SR</div>
              </div>
              <div className="col-3 align-content-center justify-content-center d-flex mt-3">
                <div className="playfa align-content-center">
                  <FontAwesomeIcon icon={faPlay} />
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-3">
                <div className="lpo1">Open 6:00 am</div>
              </div>
              <div className="col-6 align-content-center">
                <div className="lo">BID IS RUNNING</div>
              </div>
              <div className="col-3 align-content-center">
                <div className="lpo1">Close 4:00 pm</div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Footer with Buttons */}
      <div className="row fixed-footer justify-content-center d-flex">
        <div className="col-6">
          <button className="btnn3">ADD FUNDS</button>
        </div>
        <div className="col-6">
          <button className="btnn3">WITHDRAW FUNDS</button>
        </div>
      </div>
    </div>
  );
}

export default Teer;
