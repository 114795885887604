import React, { useState, useEffect } from "react";
import logo from "../Component/img/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock,
  faArrowLeft,
  faMoneyBillAlt,
} from "@fortawesome/free-solid-svg-icons";
import what from "../Component/img/what.svg";
import Telegram from "../Component/img/Telegram.webp";

function AddFund() {
  return (
    <div>
      <div className="bgred ">
        <div className="d-flex align-items-center px-2 pt-2">
          <div className="me-2">
            <FontAwesomeIcon icon={faArrowLeft} className="baricn mt-2" />
          </div>
          <div className="bbhg me-2">Withdraw Funds</div>
        </div>
      </div>
      <div className="m-2 bd2">
        <div className="bldblk1">ADD FUNDS</div>
        <div className="bldblk2 laoi">
          Add the money given below and you will get 5% cashback.
        </div>

        <div className="row mt-3">
          <div className="col">
            <div className="bgwhatsapp">
              <span>
                <img src={what} className="img-fluid imgh" />
              </span>
              <span class="text-white huj">Whatsapp</span>
            </div>
          </div>

          <div className="col">
            <div className="bgtele">
              <span>
                <img src={Telegram} className="img-fluid imgh" />
              </span>
              <span class="text-white huj">Telegram</span>
            </div>
          </div>
        </div>
      </div>

      <div className="px-3">
        <div>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-wallet2 icnn2"
              viewBox="0 0 16 16"
            >
              <path d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5z"></path>
            </svg>
          </span>
          <span class="wlt2 px-2">1062</span>
        </div>

        <div className="input-container">
          <FontAwesomeIcon icon={faMoneyBillAlt} className="input-icon" />
          <input
            type="text"
            placeholder="Enter Points"
            className="login-input"
          />
        </div>

        <div className="mt-3 mx-3 hygf p-2">
        <div class="form-check  text-start">
  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
  <label class="form-check-label asju" for="flexRadioDefault1">
    Google Pay
  </label>
</div>

<div class="form-check  text-start">
  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
  <label class="form-check-label asju" for="flexRadioDefault1">
   Others
  </label>
</div>
        </div>

        <div class="row fixed-footer2 justify-content-center d-flex">
          <div class="col-12">
            <button class="btnn3">ADD REQUEST</button>
          </div>
           
        </div>
      </div>
    </div>
  );
}

export default AddFund;
