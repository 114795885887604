import React, { useState, useEffect } from 'react';
import logo from '../Component/img/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUser } from '@fortawesome/free-solid-svg-icons'; 

function Login2() {
  const [showLogo, setShowLogo] = useState(true);

  useEffect(() => {
    // Set a timeout to hide the logo after 2 seconds
    const timer = setTimeout(() => {
      setShowLogo(false);
    }, 2000);

    // Clean up the timeout if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>

{showLogo && (
          <div className="bg-redd">
            <div className="mt-3">
              <img src={logo} className="img-fluid ft1" alt="Logo" />
            </div>
          </div>
        )}

      <div className="bgred"></div>
      <div className="mt-3">
        <img src={logo} className="img-fluid ft1" alt="Logo" />
      </div>

      <div className="input-container">
        <FontAwesomeIcon icon={faUser} className="input-icon" />
        <input type="text" placeholder="Phone Number" className="login-input" />
      </div>

      <div className="input-container">
        <FontAwesomeIcon icon={faLock} className="input-icon" />
        <input type="text" placeholder="Password" className="login-input" />
      </div>

      <div class="form-check input-container text-start mt-1 px-5">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
  <label class="form-check-label text-danger" for="flexCheckDefault">
    Show Password
  </label>
</div>

      <div>
        <button className="btnn">Login</button>
      </div>
       
      <div>
        <button className="btnn">Login With OTP</button>
      </div>

      <div className="row">
        <div className='col'>
        <div className="fg1 text-center mx-3 mt-2">FORGET PASSWORD?</div>
        </div>
        <div className='col'>
        <div className="fg1 text-center mx-3 mt-2">NEW USER?</div>
        </div>
      </div>
      
      
    </div>
  );
}

export default Login2;
