import React from "react";
import logo from "../Component/img/logo.png";
import Offcanvas from "../Component/Offcanvas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faUser,
  faMoneyBillAlt,
} from "@fortawesome/free-solid-svg-icons";

function FcBidHistory() {
  return (
    <div className="vh-100">
      <div className="bgred ">
        <div className="d-flex align-items-center px-2 pt-2">
          <div className="me-2">
            <FontAwesomeIcon icon={faArrowLeft} className="baricn mt-2" />
          </div>
          <div className="bbhg me-2">FC Bid History</div>
        </div>
      </div>

      <div className=" m-2 ">
        <div className="row m-0 p-0 g-3">
          <div className="col">
              <div className="card py-2 px-3">
                  <div className="ty2 lo">From Date</div>
                  <div className="ty1 lo">17</div>
                  <div className="ty1 lo">Sep</div>
                  <div className="ty1 lo">2024</div>
              </div>
          </div>
          <div className="col">
              <div className="card py-2 px-3">
                  <div className="ty2 lo">To Date</div>
                  <div className="ty1 lo">17</div>
                  <div className="ty1 lo">Sep</div>
                  <div className="ty1 lo">2024</div>
              </div>
          </div>
        </div>
        <div>
        <button className="btnn">Submit</button>
      </div>
      </div>
    </div>
  );
}

export default FcBidHistory;
