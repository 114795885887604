import React, { useState, useEffect } from 'react';
import logo from '../Component/img/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faArrowLeft } from '@fortawesome/free-solid-svg-icons'; 

function HowToPlay() {
 
  

  return (
    <div>
  
      <div className="bgred ">
        <div className="d-flex align-items-center px-2 pt-2">
          <div className="me-2">
            <FontAwesomeIcon icon={faArrowLeft} className="baricn mt-2" />
          </div>
          <div className="bbhg me-2">How To Play</div>
        </div>
      </div>

      <div className="m-2 bd2">
          <div className="bldblk">How To Play</div>
        For Transferring Money Wallet1 to WALLET2, 15% will be charged for per transaction
      </div>
   
      
    </div>
  );
}

export default HowToPlay;
