import React, { useState, useEffect } from "react";
import logo from "../Component/img/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faArrowLeft, faMoneyBillAlt } from "@fortawesome/free-solid-svg-icons";
import what from "../Component/img/what.svg";
import Telegram from "../Component/img/Telegram.webp";
function WithDrawFund() {
  return (
    <div>
      <div className="bgred ">
        <div className="d-flex align-items-center px-2 pt-2">
          <div className="me-2">
            <FontAwesomeIcon icon={faArrowLeft} className="baricn mt-2" />
          </div>
          <div className="bbhg me-2">Withdraw Funds</div>
        </div>
      </div>
      <div className="m-2 bd2">
        <div className="bldblk1">Withdraw</div>
        <div className="bldblk2">
          Read this carefully Before Withdrawing Your Money. 1.) Withdraw
          request can be done once in a day. 2.) You will get 4 Hours To
          Withdraw your amount Between 10:00 AM To 2:00 PM, EveryDay Except
          Saturdays And Sundays. 3.) After Withdraw Request You need to wait For
          15-30 minutes for approval. 4.) Minimum Withdrawal Amount is 500
          Rupees And maximum 10,000 per day. WhatsApp Telegram
        </div>

        <div className="row mt-2 mb-2">
          <div className="col">
            <div className="bgwhatsapp">
              <span>
                <img src={what} className="img-fluid imgh" />
              </span>
              <span class="text-white huj">Whatsapp</span>
            </div>
          </div>

          <div className="col">
            <div className="bgtele">
              <span>
                <img src={Telegram} className="img-fluid imgh" />
              </span>
              <span class="text-white huj">Telegram</span>
            </div>
          </div>
        </div>

        <div className="bldblk2">
        5.) If you
      want to Withdraw a higher number of Amount like 15,000 Rupees To 1,00,000
      Rupees You need to Complete Your KYC First. 6.) Withdrawal processing &
      bank transfer GST will be charged 5% GST Rate varried by different state.
      7.) To Verify your KYC please Contact to 'CUSTOMER SUPPORT 'Terms and
      Conditions Apply" 294490 Enter Points WITHDRAW Click here: Transfer wallet
      amount to Winning amount
        </div>
      </div>

      <div className="px-3">
      <div className="input-container">
        <FontAwesomeIcon icon={faMoneyBillAlt} className="input-icon" />
        <input type="text" placeholder="Enter Points" className="login-input" />
      </div>
 

        <div className="mt-3 mx-3">
          <button className="btnn3 py-3">WITHDRAW</button>
        </div>
<div className="lpoa">Click here: Transfer wallet amount to winning amount</div>

      </div>
    
    </div>
  );
}

export default WithDrawFund;
