import React from "react";
import home from "./img/home.png"; // Adjust this path based on the actual location of 'home.png'

function Offcanvas() {
  const containerStyle = {
    display: "flex",
    alignItems: "center",
  };

  const iconStyle = {
    marginRight: "10px",
  };

  const textStyle = {
    color: "white",
    textAlign: "start",
  };

  return (
    <>
      {/* Offcanvas component from top */}
      <div
        className="offcanvas offcanvas-top cnva vh-100"
        tabIndex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header back1">
          <div style={containerStyle}>
            <div style={iconStyle}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                className="text-white bi bi-person-fill"
                viewBox="0 0 16 16"
              >
                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
              </svg>
            </div>
            <div style={textStyle}>
              <h5 className="offcanvas-title" id="offcanvasExampleLabel">
                Sahu
              </h5>
              <p>9999999999</p>
            </div>
          </div>

          <button
            type="button"
            className="btn-close text-reset text-white"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          {/* <div>
            Some text as a placeholder. In real life, you can have the elements you have chosen, like text, images, lists, etc.
          </div> */}

          <div className="row mb-2">
            <div className="col-3">
              <img src={home} alt="Home" className="img-fluid im1" />
            </div>
            <div className="col-9 m-0 p-0">
              <div className="men1 text-start">Home</div>
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-3">
              <img src={home} alt="Home" className="img-fluid im1" />
            </div>
            <div className="col-9 m-0 p-0">
              <div className="men1 text-start">Profile</div>
            </div>
          </div>


          <div className="row mb-2">
            <div className="col-3">
              <img src={home} alt="Home" className="img-fluid im1" />
            </div>
            <div className="col-9 m-0 p-0">
              <div className="men1 text-start">Add Bank Account</div>
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-3">
              <img src={home} alt="Home" className="img-fluid im1" />
            </div>
            <div className="col-9 m-0 p-0">
              <div className="men1 text-start">Game History</div>
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-3">
              <img src={home} alt="Home" className="img-fluid im1" />
            </div>
            <div className="col-9 m-0 p-0">
              <div className="men1 text-start">Points History</div>
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-3">
              <img src={home} alt="Home" className="img-fluid im1" />
            </div>
            <div className="col-9 m-0 p-0">
              <div className="men1 text-start">Refer History</div>
            </div>
          </div>
 

          <div className="row mb-2">
            <div className="col-3">
              <img src={home} alt="Home" className="img-fluid im1" />
            </div>
            <div className="col-9 m-0 p-0">
              <div className="men1 text-start">Friends Transfer History</div>
            </div>
          </div>


          <div className="row mb-2">
            <div className="col-3">
              <img src={home} alt="Home" className="img-fluid im1" />
            </div>
            <div className="col-9 m-0 p-0">
              <div className="men1 text-start">FC Bid History</div>
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-3">
              <img src={home} alt="Home" className="img-fluid im1" />
            </div>
            <div className="col-9 m-0 p-0">
              <div className="men1 text-start">BS Bid History</div>
            </div>
          </div>


          <div className="row mb-2">
            <div className="col-3">
              <img src={home} alt="Home" className="img-fluid im1" />
            </div>
            <div className="col-9 m-0 p-0">
              <div className="men1 text-start">Add Points</div>
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-3">
              <img src={home} alt="Home" className="img-fluid im1" />
            </div>
            <div className="col-9 m-0 p-0">
              <div className="men1 text-start">Withdraw Points</div>
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-3">
              <img src={home} alt="Home" className="img-fluid im1" />
            </div>
            <div className="col-9 m-0 p-0">
              <div className="men1 text-start">How To Play</div>
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-3">
              <img src={home} alt="Home" className="img-fluid im1" />
            </div>
            <div className="col-9 m-0 p-0">
              <div className="men1 text-start">Game Rates</div>
            </div>
          </div>


          <div className="row mb-2">
            <div className="col-3">
              <img src={home} alt="Home" className="img-fluid im1" />
            </div>
            <div className="col-9 m-0 p-0">
              <div className="men1 text-start">Wallet1 to Wallet2 Transfer</div>
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-3">
              <img src={home} alt="Home" className="img-fluid im1" />
            </div>
            <div className="col-9 m-0 p-0">
              <div className="men1 text-start">Send Money to Friend</div>
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-3">
              <img src={home} alt="Home" className="img-fluid im1" />
            </div>
            <div className="col-9 m-0 p-0">
              <div className="men1 text-start">Help & Support</div>
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-3">
              <img src={home} alt="Home" className="img-fluid im1" />
            </div>
            <div className="col-9 m-0 p-0">
              <div className="men1 text-start">Log Out</div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}

export default Offcanvas;
