import React, { useState, useEffect } from 'react';
import logo from '../Component/img/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faArrowLeft } from '@fortawesome/free-solid-svg-icons'; 

function ChangePassword() {
 
  

  return (
    <div>
  
      <div className="bgred ">
        <div className="d-flex align-items-center px-2 pt-2">
          <div className="me-2">
            <FontAwesomeIcon icon={faArrowLeft} className="baricn mt-2" />
          </div>
          <div className="bbhg me-2">Change Password</div>
        </div>
      </div>

      <div className="input-container">
        <FontAwesomeIcon icon={faLock} className="input-icon" />
        <input type="text" placeholder="Current Password" className="login-input" />
      </div>

      <div className="input-container">
        <FontAwesomeIcon icon={faLock} className="input-icon" />
        <input type="text" placeholder="New Password" className="login-input" />
      </div>

      <div className="input-container">
        <FontAwesomeIcon icon={faLock} className="input-icon" />
        <input type="text" placeholder="Confirm New Password" className="login-input" />
      </div>

      <div class="form-check input-container text-start mt-1 px-5">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
  <label class="form-check-label text-danger" for="flexCheckDefault">
    Show Password
  </label>
</div>

      <div>
        <button className="btnn">Change Password</button>
      </div>
       
      
    </div>
  );
}

export default ChangePassword;
