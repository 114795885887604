import React from "react";
import logo from "../Component/img/logo.png";
import Offcanvas from "../Component/Offcanvas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faUser,
  faMoneyBillAlt,
} from "@fortawesome/free-solid-svg-icons";

function Profile() {
  return (
    <div className="vh-100 bgred44">
      <div className="bgred ">
        <div className="d-flex align-items-center px-2 pt-2">
          <div className="me-2">
            <FontAwesomeIcon icon={faArrowLeft} className="baricn mt-2" />
          </div>
          <div className="bbhg me-2">Profile</div>
        </div>
      </div>

      <div className="m-2 bg-white bdrs">
        <div className="row m-0 p-0 g-3">
          <div className="col">
            <div className="py-2 px-3">
              <div className="ty3 lo">Profile</div>

              <div class="input-container2 ">
                <div class="text-start">
                  <label class="lbl12">Mobile</label>
                </div>
                <input
                  type="text"
                  placeholder="Mobile Number"
                  class="login-input2"
                />
              </div>


              <div class="input-container2 ">
                <div class="text-start">
                  <label class="lbl12">Email</label>
                </div>
                <input
                  type="text"
                  placeholder="Email"
                  class="login-input2"
                />
              </div>


              <div class="input-container2 ">
                <div class="text-start">
                  <label class="lbl12">Date Of birth</label>
                </div>
                <input
                  type="text"
                  placeholder="1-7-1990"
                  class="login-input2"
                />
              </div>

               
            </div>
          </div>
        </div>
        <div>
          <button className="btnn mb-3">Update</button>
        </div>
      </div>



      <div className="m-2 bg-white bdrs">
        <div className="row m-0 p-0 g-3">
          <div className="col">
            <div className="py-2 px-3">
              <div className="ty3 lo">Address</div>

              <div class="input-container2 ">
                <div class="text-start">
                  <label class="lbl12">Address</label>
                </div>
                <input
                  type="text"
                  placeholder="Address"
                  class="login-input2"
                />
              </div>


              <div class="input-container2 ">
                <div class="text-start">
                  <label class="lbl12">City</label>
                </div>
                <input
                  type="text"
                  placeholder="City"
                  class="login-input2"
                />
              </div>


              <div class="input-container2 ">
                <div class="text-start">
                  <label class="lbl12">Pincode</label>
                </div>
                <input
                  type="text"
                  placeholder="Pincode"
                  class="login-input2"
                />
              </div>

               
            </div>
          </div>
        </div>
        <div>
          <button className="btnn mb-3">Add Address</button>
        </div>
      </div>
    </div>
  );
}

export default Profile;
