import React from "react";
import logo from "../Component/img/logo.png";  
import Offcanvas from "../Component/Offcanvas";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faUser, faMoneyBillAlt } from '@fortawesome/free-solid-svg-icons'; 


function GameRate() {
  return (
    <div className="vh-100">
      <div className="bgred ">
        <div className="d-flex align-items-center px-2 pt-2">
          <div className="me-2">
            <FontAwesomeIcon icon={faArrowLeft} className="baricn mt-2" />
          </div>
          <div className="bbhg me-2">Game Rates</div>
        </div>
      </div>

     

      <div className=" m-2 ">
        <div className="row">
          <div className="col py-3">
            <div className="text-center rate1">GAME RATES</div>

            <table className="table table-bordered tbl1 tbl2 mb-1 mt-1">
                <tr className=" ">
                    <td className="text-start py-2 pop1">SHILLONG TEER FR</td>
                    <td className="text-start py-2 pop2">10 KA 800</td>
                </tr>

                <tr className=" ">
                    <td className="text-start py-2 pop1">SHILLONG TEER FR</td>
                    <td className="text-start py-2 pop2">10 KA 800</td>
                </tr>

                <tr className=" ">
                    <td className="text-start py-2 pop1">SHILLONG TEER FR</td>
                    <td className="text-start py-2 pop2">10 KA 800</td>
                </tr>

                <tr className=" ">
                    <td className="text-start py-2 pop1">SHILLONG TEER FR</td>
                    <td className="text-start py-2 pop2">10 KA 800</td>
                </tr>

                <tr className=" ">
                    <td className="text-start py-2 pop1">SHILLONG TEER FR</td>
                    <td className="text-start py-2 pop2">10 KA 800</td>
                </tr>

                <tr className=" ">
                    <td className="text-start py-2 pop1">SHILLONG TEER FR</td>
                    <td className="text-start py-2 pop2">10 KA 800</td>
                </tr>

                <tr className=" ">
                    <td className="text-start py-2 pop1">SHILLONG TEER FR</td>
                    <td className="text-start py-2 pop2">10 KA 800</td>
                </tr>

                <tr className=" ">
                    <td className="text-start py-2 pop1">SHILLONG TEER FR</td>
                    <td className="text-start py-2 pop2">10 KA 800</td>
                </tr>
            </table>

            <div className="text-center rate2"></div>
          </div>

          
        </div>
      </div>

     
    </div>
  );
}

export default GameRate;
