import React from "react";
import logo from "../Component/img/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faBars, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "../Component/Offcanvas";

function HousingAndEnding() {
  return (
    <div className="vh-100">
      {/* Offcanvas Component */}
      <Offcanvas />

      {/* Background */}
      <div className="bgred ">
        <div className="row">
          <div className="col-2">
            <FontAwesomeIcon icon={faArrowLeft} className="baricn mt-3" />
          </div>
        </div>
      </div>

      {/* Date Button */}
      <div className="mt-3">
        <div className="lo txt-red">HOUSING & ENDING</div>
        <div className="btnn4">10/09/2024 TUESDAY BET RUNNING</div>
      </div>

      <div className="row p-0 m-0 over vh-75">
        <div className="col ">
          <div className="lo txt-red">HOUSING</div>
          <div className="mt-2" style={{ display: "flex", gap: "10px" }}>
            <input type="text" className="form-control mxinp" value="0" />
            <input type="text" className="form-control mxinp2" placeholder="Amour" />
          </div>

          <div className="mt-2" style={{ display: "flex", gap: "10px" }}>
            <input type="text" className="form-control mxinp" value="1" />
            <input type="text" className="form-control mxinp2" placeholder="Amour" />
          </div>

          <div className="mt-2" style={{ display: "flex", gap: "10px" }}>
            <input type="text" className="form-control mxinp" value="2" />
            <input type="text" className="form-control mxinp2" placeholder="Amour" />
          </div>
          <div className="mt-2" style={{ display: "flex", gap: "10px" }}>
            <input type="text" className="form-control mxinp" value="3" />
            <input type="text" className="form-control mxinp2" placeholder="Amour" />
          </div>
          <div className="mt-2" style={{ display: "flex", gap: "10px" }}>
            <input type="text" className="form-control mxinp" value="4" />
            <input type="text" className="form-control mxinp2" placeholder="Amour" />
          </div>
          <div className="mt-2" style={{ display: "flex", gap: "10px" }}>
            <input type="text" className="form-control mxinp" value="5" />
            <input type="text" className="form-control mxinp2" placeholder="Amour" />
          </div>
          <div className="mt-2" style={{ display: "flex", gap: "10px" }}>
            <input type="text" className="form-control mxinp" value="6" />
            <input type="text" className="form-control mxinp2" placeholder="Amour" />
          </div>
          <div className="mt-2" style={{ display: "flex", gap: "10px" }}>
            <input type="text" className="form-control mxinp" value="7" />
            <input type="text" className="form-control mxinp2" placeholder="Amour" />
          </div>
          <div className="mt-2" style={{ display: "flex", gap: "10px" }}>
            <input type="text" className="form-control mxinp" value="8" />
            <input type="text" className="form-control mxinp2" placeholder="Amour" />
          </div>
          <div className="mt-2" style={{ display: "flex", gap: "10px" }}>
            <input type="text" className="form-control mxinp" value="9" />
            <input type="text" className="form-control mxinp2" placeholder="Amour" />
          </div>
        </div>

        <div className="col ">
          <div className="lo txt-red">ENDING</div>
          <div className="mt-2 " style={{ display: "flex", gap: "10px" }}>
            <input type="text" className="form-control mxinp" value="0" />
            <input type="text" className="form-control mxinp2" placeholder="Amour" />
          </div>

          <div className="mt-2" style={{ display: "flex", gap: "10px" }}>
            <input type="text" className="form-control mxinp" value="1" />
            <input type="text" className="form-control mxinp2" placeholder="Amour" />
          </div>

          <div className="mt-2" style={{ display: "flex", gap: "10px" }}>
            <input type="text" className="form-control mxinp" value="2" />
            <input type="text" className="form-control mxinp2" placeholder="Amour" />
          </div>
          <div className="mt-2" style={{ display: "flex", gap: "10px" }}>
            <input type="text" className="form-control mxinp" value="3" />
            <input type="text" className="form-control mxinp2" placeholder="Amour" />
          </div>
          <div className="mt-2" style={{ display: "flex", gap: "10px" }}>
            <input type="text" className="form-control mxinp" value="4" />
            <input type="text" className="form-control mxinp2" placeholder="Amour" />
          </div>
          <div className="mt-2" style={{ display: "flex", gap: "10px" }}>
            <input type="text" className="form-control mxinp" value="5" />
            <input type="text" className="form-control mxinp2" placeholder="Amour" />
          </div>
          <div className="mt-2" style={{ display: "flex", gap: "10px" }}>
            <input type="text" className="form-control mxinp" value="6" />
            <input type="text" className="form-control mxinp2" placeholder="Amour" />
          </div>
          <div className="mt-2" style={{ display: "flex", gap: "10px" }}>
            <input type="text" className="form-control mxinp" value="7" />
            <input type="text" className="form-control mxinp2" placeholder="Amour" />
          </div>
          <div className="mt-2" style={{ display: "flex", gap: "10px" }}>
            <input type="text" className="form-control mxinp" value="8" />
            <input type="text" className="form-control mxinp2" placeholder="Amour" />
          </div>
          <div className="mt-2" style={{ display: "flex", gap: "10px" }}>
            <input type="text" className="form-control mxinp" value="9" />
            <input type="text" className="form-control mxinp2" placeholder="Amour" />
          </div>
        </div>
      </div>

      <div className="row align-items-center mt-3">
        <div className="col">
        <div> <strong>Total :</strong> </div>
        </div>
        <div className="col p">
        <div><input type="text" className="form-control mxinp2" placeholder="Amour" /></div>
        </div>
         
      </div>

      <div>
      <div className="btnn4">SUBMIT</div>
      </div>

      
    </div>
  );
}

export default HousingAndEnding;
